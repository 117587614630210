@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  input:focus-within ~ label,
  input:not(:placeholder-shown) ~ label {
    @apply transform scale-75 -translate-y-5;
  }

  input:-webkit-autofill ~ label {
    @apply transform scale-75 -translate-y-5;
  }
  /*Change text in autofill textbox*/
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #fffef2 inset !important;
    -webkit-text-fill-color: #002c9a !important;
  }

  &:-webkit-autofill::first-line {
    font-family: "ValueSans";
  }
}

@font-face {
  font-family: "ValueSans";
  src: local("ValueSans"),
    url(./assets/fonts/value-sans-regular.otf) format("woff");
}

@font-face {
  font-family: "ValueSans";
  font-weight: 500;
  src: local("ValueSans"),
    url(./assets/fonts/value-sans-medium.otf) format("woff");
}

body {
  margin: 0;
  font-family: "ValueSans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fffef2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-10%, 0, 0);
    transform: translate3d(-10%, 0, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.animate__error {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}

.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}